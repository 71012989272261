import React from 'react';

import 'whatwg-fetch';
import './currency-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function CurrencyPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="dovizId" textField="dovizAdi" codeField="dovizKodu" stringField="Doviz" stringFieldPulural="Dovizler" title="Döviz" portfoyTipiId={4}  />
    </React.Fragment>
  )
}