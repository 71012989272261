export const serviceUrl = "https://api.yatirimi.com/api";
// export const serviceUrl = "https://fonapi.losmedia.com/api";
// export const serviceUrl = "https://localhost:7276/api";
export const serviceKey = "fee!!_rjg.53g56jt78!:_";


export async function signIn(eposta, sifre) {
  try {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("guestToken"));
    // myHeaders.append("Authorization", "Bearer " + defaultUser.token);


    var payload = {
      "Eposta": eposta,
      "Sifre": sifre
    }

    var requestLoginOptions = {
      method: 'POST',
      headers: myHeaders,
      include: false,
      body: JSON.stringify(payload),
    };


    const response = await fetch(`${serviceUrl}/Kullanici/GirisYap`, requestLoginOptions);
    if (response.ok) {
      const result = await response.json();
      localStorage.setItem("token", result.token);
      localStorage.setItem("eposta", result.eposta);
      localStorage.setItem("kullaniciId", result.kullaniciId);
      localStorage.setItem("onaylandiMi", result.onaylandiMi);

      let defaultUser = {
        token: localStorage.getItem("token"),
        eposta: localStorage.getItem("eposta"),
        onaylandiMi: localStorage.getItem("onaylandiMi"),
        kullaniciId: localStorage.getItem("kullaniciId"),
      }

      return {
        isOk: true,
        data: defaultUser
      };
    } else {
      const result = await response.text();
      let message = "Hata: " + result;
      return {
        isOk: false,
        message: message
      };
    }


  }
  catch (error) {
    let message = "Hata: " + error.toString()
    return {
      isOk: false,
      message: message
    };
  }
}

export async function getUser() {
  try {

    let defaultUser = {
      token: localStorage.getItem("token"),
      eposta: localStorage.getItem("eposta"),
      onaylandiMi: localStorage.getItem("onaylandiMi"),
      kullaniciId: localStorage.getItem("kullaniciId"),
    }

    if (defaultUser.kullaniciId === undefined || defaultUser.kullaniciId === null) {
      await getGuestToken();
      return {
        isOk: false,
        data: undefined
      };
    }


    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(eposta, sifre) {
  try {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("guestToken"));

    var payload = {
      "Eposta": eposta,
      "Sifre": sifre
    }

    var requestRegisterOptions = {
      method: 'POST',
      headers: myHeaders,
      include: false,
      body: JSON.stringify(payload),
    };


    const response = await fetch(`${serviceUrl}/Kullanici/UyeOl`, requestRegisterOptions);
    if (response.ok) {
      const result = await response.json();
      localStorage.setItem("token", result.token);
      localStorage.setItem("eposta", result.eposta);
      localStorage.setItem("kullaniciId", result.kullaniciId);
      localStorage.setItem("onaylandiMi", result.onaylandiMi);

      let defaultUser = {
        token: localStorage.getItem("token"),
        eposta: localStorage.getItem("eposta"),
        onaylandiMi: localStorage.getItem("onaylandiMi"),
        kullaniciId: localStorage.getItem("kullaniciId"),
      }

      return {
        isOk: true,
        data: defaultUser
      };
    } else {
      const result = await response.text();
      let message = "Hata: " + result;
      return {
        isOk: false,
        message: message
      };
    }


  }
  catch (error) {
    let message = "Hata: " + error.toString()
    return {
      isOk: false,
      message: message
    };
  }
}

export async function changePassword(kod, sifre) {
  try {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("guestToken"));

    var payload = {
      "Kod": kod,
      "Sifre": sifre
    }

    var requestChangePasswordOptions = {
      method: 'POST',
      headers: myHeaders,
      include: false,
      body: JSON.stringify(payload),
    };


    const response = await fetch(`${serviceUrl}/Kullanici/SifreDegistir`, requestChangePasswordOptions);
    if (response.ok) {
      return {
        isOk: true,
      };
    } else {
      const result = await response.text();
      let message = "Hata: " + result;
      return {
        isOk: false,
        message: message
      };
    }


  }
  catch (error) {
    let message = "Hata: " + error.toString()
    return {
      isOk: false,
      message: message
    };
  }
}

export async function resetPassword(eposta) {
  try {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("guestToken"));

    var payload = {
      "Eposta": eposta,
    }

    var requestResetPasswordOptions = {
      method: 'POST',
      headers: myHeaders,
      include: false,
      body: JSON.stringify(payload),
    };


    const response = await fetch(`${serviceUrl}/Kullanici/SifreSifirla`, requestResetPasswordOptions);
    if (response.ok) {
      return {
        isOk: true,
      };
    } else {
      const result = await response.text();
      let message = "Hata: " + result;
      return {
        isOk: false,
        message: message
      };
    }


  }
  catch (error) {
    let message = "Hata: " + error.toString()
    return {
      isOk: false,
      message: message
    };
  }
}


export async function getGuestToken() {
  try {


    var requestLoginOptions = {
      method: 'GET',
    };


    await fetch(`${serviceUrl}/Kullanici/GetToken/` + encodeURI(serviceKey), requestLoginOptions)
      .then(response => {

        if (response.ok) {
          response.text().then(result => {
            localStorage.setItem("guestToken", result);
          });
        }
        else {
          response.text().then(result => {
            console.log(result);
          });
        }
      })

  }
  catch (error) {
    let message = "Hata: " + error.toString()
    return {
      isOk: false,
      message: message
    };
  }
}
