import { useAuth, serviceUrl } from '../contexts/auth';

// const { user } = useAuth();



export default async function fetchData(endpoint, method, user, payload = undefined)
{


    try {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + user.token);

        var proxyOptions = {
            method: method,
            headers: myHeaders,
        };

        if (payload !== undefined) {
            proxyOptions.body = JSON.stringify(payload)
        }


        const response = await fetch(`${serviceUrl}` + endpoint, proxyOptions);
        // debugger;
        if (response.ok && response.status === 200) {
            const data = await response.json();
            return data;
        } else {

            const text = await response.text();
            return text;
        }
    } catch (error) {
        console.log(error.message);
    }
};
