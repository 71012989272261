import React from 'react';


export default function RiskUyarisi() {

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Risk Uyarısı</h4>

          <p>Finansal araçlar, risk içeren varlıklardır ve yatırımcılar bu riskleri kabul etmelidir. İşlem gerçekleştirdiğinizde kazanabileceğiniz gibi aynı zamanda yatırım miktarınızın bir kısmını veya tamamını kaybedebilirsiniz. Yatırımlar her zaman kazanç garantisi sunmaz ve yatırımcılar, yatırdıkları tüm parayı kaybetme riskini de göze almalıdır.</p>

          <b>Önemli Riskler</b>

          <ul>
            <li>Piyasa riski: Piyasa koşullarındaki değişiklikler, yatırım fiyatlarının dalgalanmasına ve değer kayıplarına neden olabilir.</li>
            <li>Likidite riski: Bazı yatırım araçları, alıcı veya satıcı bulmanın zor olduğu durumlarda likit olmayabilir.</li>
            <li>Karşı taraf riski: Bir işlemdeki karşı tarafın yükümlülüklerini yerine getirememe riski.</li>
            <li>Kaldıraç riski: Kaldıraç kullanımı, potansiyel kazançları ve kayıpları artırabilir.</li>
            <li>Operasyonel risk: İşlem hataları veya sistem arızaları gibi operasyonel sorunlar, yatırımcılara zarar verebilir.</li>
            <li>Yasal ve düzenleyici riskler: Yasal ve düzenleyici değişiklikler, yatırım araçlarının değerini ve yatırımcıların haklarını etkileyebilir.</li>
          </ul>

          <b>Yatırımcı Tavsiyeleri</b>

          <ul>
            <li>Yatırım yapmadan önce risk profilinizi belirleyin.</li>
            <li>Yatırım araçlarının işleyişini ve risklerini iyice anlayın.</li>
            <li>Gerçekçi hedefler belirleyin ve bir yatırım planı oluşturun.</li>
            <li>Diversifikasyon yoluyla risklerinizi azaltın.</li>
            <li>Duygusal kararlar vermemeye çalışın.</li>
            <li>Gerekirse profesyonel destek alın.</li>
          </ul>

          <p>Yatirimi.com veya yatirimi mobil uygulaması veya herhangi bir yer sağlayıcı, buradaki bilgileri kullanmanız sonucu oluşacak olası kayıplardan ötürü sorumluluk taşımamaktadır. Yatırımcılar, sitede yer alan bilgileri kullanmadan önce kendi araştırmalarını yapmalı ve riskleri kabul etmelidir. Yatirimi.com veya yatirimi mobil uygulaması, yatırımcıların yatırım kararlarından sorumlu değildir.</p>

          <b>Sorumlu Yatırımcılık</b>

          <p>Yatirimi.com, sorumlu yatırımcılık ilkelerini benimsemektedir. Yatırımcıların, çevresel, sosyal ve yönetişimsel (ESG) kriterleri göz önünde bulundurarak yatırım yapmaları teşvik edilmektedir.</p>

          <b>Ek Bilgiler</b>

          <ul>
            <li>Siber Güvenlik: Yatırımcıların siber saldırılara karşı dikkatli olması ve hesap bilgilerini korumak için gerekli önlemleri alması önemlidir.</li>
            <li>Vergisel Yükümlülükler: Yatırımlardan elde edilen kazançların vergilendirilebileceği ve yatırımcıların bu konuda bilgi sahibi olması gerektiği unutulmamalıdır.</li>
            <li>Yatırım Tavsiyesi Değildir: Sitede yer alan bilgiler yatırım tavsiyesi değildir ve yatırımcılar kendi araştırmalarını yapmalıdır.</li>
          </ul>

          <p>Yatırımcıların, yatırım yapmadan önce bu bilgileri dikkatlice okumaları ve anlamaları tavsiye edilir.</p>

          <b>İletişim Bilgileri</b>

          <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <ul>
            <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
          </ul>

        </div>
      </div>
    </React.Fragment>
  )
}