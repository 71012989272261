import {
    HomePage, TasksPage,
    // ProfilePage, 
    FundPortfolioPage, GizlilikPolitikasiPage, 
    RiskUyarisiPage, FeragatBildirisiPage, CerezPolitikasiPage, KullanimKosullariPage, KisiselVerilerinKorunmasiVeIslenmesiPage,
    SharesPortfolioPage, MetalsPortfolioPage, CurrencyPortfolioPage, AssetsPortfolioPage, CryptoPortfolioPage, PerformanceReportPage,
    // UyelikSozlesmesiPage
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';


const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    // {
    //     path: '/profile',
    //     element: ProfilePage
    // },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/fon-portfoyum',
        element: FundPortfolioPage
    },
    {
        path: '/hisse-portfoyum',
        element: SharesPortfolioPage
    },
    {
        path: '/kiymetli-madenler-portfoyum',
        element: MetalsPortfolioPage
    },
    {
        path: '/doviz-portfoyum',
        element: CurrencyPortfolioPage
    },
    {
        path: '/varlik-portfoyum',
        element: AssetsPortfolioPage
    },
    {
        path: '/kripto-portfoyum',
        element: CryptoPortfolioPage
    },
    {
        path: '/performans-raporu',
        element: PerformanceReportPage
    },
    {
        path: '/gizlilik-politikasi',
        element: GizlilikPolitikasiPage
    },
    {
        path: '/risk-uyarisi',
        element: RiskUyarisiPage
    },
    {
        path: '/feragat-bildirisi',
        element: FeragatBildirisiPage
    },
    {
        path: '/cerez-politikasi',
        element: CerezPolitikasiPage
    },
    {
        path: '/kullanim-kosullari',
        element: KullanimKosullariPage
    },
    // {
    //     path: '/uyelik-sozlesmesi',
    //     element: UyelikSozlesmesiPage
    // },
    {
        path: '/kisisel-verilerin-korunmasi-ve-islenmesi',
        element: KisiselVerilerinKorunmasiVeIslenmesiPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
