import React, { useState, useRef, useEffect } from 'react';

import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import DataGrid, {
  // Column,
  Paging,
  FilterRow,
  // Lookup,
  Editing,
  Summary,
  TotalItem,
  GroupItem,
  Scrolling,
  Grouping,
  GroupPanel,
  Export,
  SortByGroupSummaryInfo,
  SearchPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';

import CustomStore from 'devextreme/data/custom_store';

import SelectBox from 'devextreme-react/select-box';
import { useAuth, serviceUrl } from '../../contexts/auth';

import fetchData from '../../utils/data-fetcher';

import notify from 'devextreme/ui/notify';

export default function PortfolioGrid({ keyField, textField, codeField, stringField, stringFieldPulural, title, portfoyTipiId }) {
  const { user } = useAuth();
  let sbPortfoylerim = useRef(null);
  let grid = useRef(null); // Create a ref
  const [sonGuncellemeTarihi, setSonGuncellemeTarihi] = useState(null);
  const [portfoyId, setPortfoyId] = useState(null);
  const [usdtFiyati, setUsdtFiyati] = useState(null);

  let mainData = [];




  const gridDataSource = new CustomStore({
    key: 'portfoyDetayId',
    load() {
      return fetchData("/PortfoyDetay/GetPortfoyDetaylar/" + portfoyId, "GET", user);
    },
    insert(values) {
      values.PortfoyId = portfoyId;
      return fetchData("/PortfoyDetay/PostPortfoyDetay", 'POST', user, values);
    },
    update(key, values) {
      return fetchData("/PortfoyDetay/PutPortfoyDetay/" + key, 'PUT', user, values);
    },
    remove(key) {
      return fetchData("/PortfoyDetay/DeletePortfoyDetay/" + key, 'DELETE', user);
    },
  });

  const portfoylerimDataSource = new CustomStore({
    key: 'portfoyId',
    loadMode: 'raw',
    onLoaded: (result) => {
      setPortfoyId(result[0].portfoyId);
      grid.current.instance.option("dataSource", gridDataSource);
    },
    load: () => {
      return fetchData("/Portfoy/GetPortfoylerim/" + portfoyTipiId, "GET", user);
    }
  });


  const entityDataSource = new CustomStore({
    key: { keyField },
    loadMode: 'raw',
    load: () => {
      let response = fetchData("/" + stringField + "/Get" + stringFieldPulural, "GET", user);

      response.then((data) => {
        mainData = data;
        // console.log("mainData", mainData);
      });

      return response;
    }
  });

  const fetchSonGuncellenmeTarihi = async () => {
    try {

      let _usdtFiyati = await fetchData("/KriptoPara/GetKriptoParaFiyat", 'POST', user, { sembol: "USDTTRY", tarih: new Date() });
      setUsdtFiyati(_usdtFiyati);

      const response = await fetchData("/" + stringField + "/Get" + stringField + "FiyatSonGuncellenmeTarihi", 'GET', user);
      setSonGuncellemeTarihi(new Date(response).toLocaleDateString("tr-TR"));
    } catch (error) {
      console.error("Hata: ", error);
    }
  };


  useEffect(() => {
    if (keyField !== "varlikId") {
      fetchSonGuncellenmeTarihi();
    }
  });


  const onEditorPreparing = function (e) {
    if ((e.dataField === "satinAlinmaTarihi" || e.dataField === keyField) && e.parentType === "dataRow") {
      debugger;
      const defaultValueChangeHandler = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = function (args) {
        defaultValueChangeHandler(args);
        // Override the default handler
        // console.log(args);
        // console.log("get values", e.row.values);
        // console.log("or get the raw data", e.row.data);
        // console.log("get the changed values from onValueChanged", args.value);
        // console.log("previous value", args.previousValue);

        e.row.rowIndex = (e.row.rowIndex === undefined ? e.row.rowIndex = 0 : e.row.rowIndex);

        if (grid.current.instance.cellValue(e.row.rowIndex, 'adet') === undefined) {
          grid.current.instance.cellValue(e.row.rowIndex, 'adet', 1);
          e.row.data.adet = 1;
        }


        if (grid.current.instance.cellValue(e.row.rowIndex, 'satinAlinmaTarihi') === undefined) {
          grid.current.instance.cellValue(e.row.rowIndex, 'satinAlinmaTarihi', new Date());
          e.row.data.satinAlinmaTarihi = new Date();
        }

        debugger;


        if (e.row.cells.filter(s => s.column.dataField === keyField).length > 0) {


          let code = "";
          if (e.row.cells.filter(s => s.column.dataField === keyField)[0].displayValue === undefined) {
            if (args.component._getPlainItems !== undefined) {
              code = args.component._getPlainItems().filter(s => s[keyField] === args.value)[0][codeField]
            }
          }
          else {
            code = e.row.cells.filter(s => s.column.dataField === keyField)[0].displayValue;
            code = code.split(' - ')[0];
          }



          var payload = {
            [codeField]: code,
            "tarih": new Date(e.row.data.satinAlinmaTarihi)
          }

          fetchData("/" + stringField + "/Get" + stringField + "Fiyat", "POST", user, payload).then(res => {
            debugger;
            if (res !== undefined && res.constructor === Number) {
              grid.current.instance.cellValue(e.row.rowIndex, 'birimMaliyet', res)
            }
            else {
              notify(res, 'error', 3000);
            }
          });
        }


      }
    }

    if (e.dataField === keyField) {

      if (e.parentType === "dataRow") {
        e.editorOptions.placeholder = "Seçiniz...";
      }
      else if (e.parentType === "filterRow") {
        e.showAllText = "(Tümü)";
      }
    }

  }

  const onExporting = function (e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        options.excelCell.font = { name: 'Arial', size: 12 };
        options.excelCell.alignment = { horizontal: 'left' };
      }
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Belge-' + new Date().toLocaleDateString("tr-TR") + '-' + new Date().toLocaleTimeString("tr-TR") + '.xlsx');
        });
    });
  }

  const isTetherValued = function (e) {
    if (e[keyField] === undefined) {
      return false;
    }
    const kod = codeField === undefined ? "" : mainData.filter(s => s[keyField] === e[keyField])[0][codeField];
    let result = (kod && kod.length > 4 && kod.slice(-4) === "USDT");
    // console.log("result", result);
    return result;
  }


  let val1 = 0;  //birimMaliyet
  let val2 = 0;  //guncelFiyat
  let val3 = 0;  //usdAlinsaydiKar
  let val4 = 0;  //adet
  let val5 = 0;  //tuikTufe
  let val6 = 0;  //adet
  let val7 = 0;  //birimMaliyet
  let val8 = 0;  //adet

  const calculateCustomSummary = function (options) {
    if (options.name === "kar") { //ağırlıklı ortalaması hesaplanıyor group kar olarak
      switch (options.summaryProcess) {
        case "start":
          val1 = 0;  //birimMaliyet
          val2 = 0;  //guncelFiyat
          // options.totalValue = 0;
          break;
        case "calculate":
          val1 += options.value.birimMaliyet * options.value.adet;
          val2 += options.value.guncelFiyat * options.value.adet;
          break;
        case "finalize":
          options.totalValue = ((val2 - val1) / val1)
          break;
        default:
          break;
      }
    }
    else if (options.name === "usdAlinsaydiKar") { //ağırlıklı ortalaması hesaplanıyor
      switch (options.summaryProcess) {
        case "start":
          val3 = 0;  //usdAlinsaydiKar
          val4 = 0;  //adet
          // options.totalValue = 0;
          break;
        case "calculate":
          val3 += options.value.usdAlinsaydiKar * options.value.adet;
          val4 += options.value.adet;
          break;
        case "finalize":
          options.totalValue = ((val3 / val4) / 100)
          break;
        default:
          break;
      }
    }
    else if (options.name === "tuikTufe") { //ağırlıklı ortalaması hesaplanıyor
      switch (options.summaryProcess) {
        case "start":
          val5 = 0;  //tuikTufe
          val6 = 0;  //adet
          break;
        case "calculate":
          val5 += options.value.tuikTufe * options.value.adet;
          val6 += options.value.adet;
          break;
        case "finalize":
          options.totalValue = ((val5 / val6) / 100)
          break;
        default:
          break;
      }
    }
    else if (options.name === "birimMaliyet") { //ağırlıklı ortalaması hesaplanıyor
      switch (options.summaryProcess) {
        case "start":
          val7 = 0;  //

          val8 = 0;  //adet
          break;
        case "calculate":
          val7 += options.value.birimMaliyet * options.value.adet;
          val8 += options.value.adet;
          break;
        case "finalize":
          options.totalValue = ((val7 / val8) / 100)
          break;
        default:
          break;
      }
    }

  };


  // debugger;
  // console.log("keyField", keyField.toString());
  // entityDataSource.load().then(res => { 
  //   console.log("mainData", res)
  //   // mainData = res;
  //  });

  const columns = [
    { dataField: "portfoyDetayId", caption: "PortfoyDetayId", visible: false },
    {
      dataField: "portfoyId",
      caption: "PortfoyId",
      visible: false,
      dataType: "number",
    },
    {
      caption: "Değiştirlebilir Veriler",
      alignment: 'center',
      cssClass: 'colSeperator',
      columns: [
        {
          dataField: keyField !== "varlikId" ? keyField : textField,
          caption: stringField,
          visible: true,
          width: '100%',
          minWidth: 300,

          ...(keyField !== "varlikId" && {
            lookup: {
              keyField: keyField,
              dataSource: entityDataSource,
              valueExpr: keyField,
              displayExpr: item => `${item[codeField]} - ${item[textField]}`
            }
          })

        },
        {
          dataField: "satinAlinmaTarihi",
          caption: "Tarihi",
          dataType: "date",
          format: "dd.MM.yyyy",
          width: '100%',
          editorOptions: {
            disabledDates: function (args) {
              const dayOfWeek = args.date.getDay();
              const isWeekend = (args.view === "month" && (dayOfWeek === 0 || dayOfWeek === 6)) || args.date > new Date();
              return isWeekend;
            }
          }
        },
        { dataField: "birimMaliyet", caption: "Birim Maliyet", width: 120, dataType: "number", format: { type: "fixedPoint", precision: 6 } },
        { dataField: "adet", caption: "Adet", dataType: "number", width: 90, cssClass: keyField === "varlikId" ? '' : 'colSeperator' },


        {
          dataField: "varlikFiyat", caption: "Güncel Fiyat", visible: keyField !== "varlikId" ? false : true, width: 120, allowEditing: true,
          dataType: "number", format: { type: "fixedPoint", precision: 2 }, cssClass: keyField === "varlikId" ? 'colSeperator' : ''
        },


      ]
    },
    {
      caption: "Otomatik Hesaplanan Veriler",
      alignment: 'center',
      cssClass: 'colSeperator',
      columns: [
        { dataField: "maliyet", caption: "Maliyet", width: 120, allowEditing: false, calculateCellValue: e => e.adet * e.birimMaliyet * (isTetherValued(e) ? usdtFiyati : 1), format: { type: "fixedPoint", precision: 2 } },
        { dataField: "guncelFiyat", caption: "Güncel Fiyat", visible: keyField !== "varlikId" ? true : false, width: 120, allowEditing: false, dataType: "number", format: { type: "fixedPoint", precision: 6 } },
        { dataField: "deger", caption: "Değer", width: 120, allowEditing: false, calculateCellValue: e => e.adet * e.guncelFiyat * (isTetherValued(e) ? usdtFiyati : 1), format: { type: "fixedPoint", precision: 2 } },
        { dataField: "gun", caption: "Gün", width: 80, allowEditing: false, dataType: "number", calculateCellValue: e => { let _dt = new Date(); _dt.setHours(0, 0, 0, 0); let _sdt = new Date(e.satinAlinmaTarihi); _sdt.setHours(0, 0, 0, 0); return Math.round((_dt.getTime() - _sdt.getTime()) / (1000 * 3600 * 24)); } },
        { dataField: "kar", caption: "Kâr", width: 90, allowEditing: false, calculateCellValue: e => (e.adet * (e.guncelFiyat - e.birimMaliyet)) / (e.adet * e.birimMaliyet), format: { type: "percent", precision: 2 } },
        {
          dataField: "gunlukOrtalamaKar", caption: "Günlük Kâr", width: 100, allowEditing: false, format: { type: "percent", precision: 6 }, sortOrder: "desc",
          calculateCellValue: e => {

            let _dt = new Date();
            _dt.setHours(0, 0, 0, 0);
            let _sdt = new Date(e.satinAlinmaTarihi);
            _sdt.setHours(0, 0, 0, 0);
            let gun = Math.round((_dt.getTime() - _sdt.getTime()) / (1000 * 3600 * 24));

            // return (((e.adet * (e.guncelFiyat - e.birimMaliyet)) / gun) * 100 ) / (e.adet * e.birimMaliyet);

            return (e.guncelFiyat / e.birimMaliyet) ** (1 / gun) / 100;

          }
        },
        { dataField: "usdAlinsaydiKar", caption: "Dolar Alınsaydı", allowEditing: false, width: 100, calculateCellValue: e => e.usdAlinsaydiKar / 100, format: { type: "percent", precision: 2 } },
        { dataField: "tuikTufe", caption: "Tüik Tüfe**", allowEditing: false, width: 100, calculateCellValue: e => e.tuikTufe / 100, format: { type: "percent", precision: 2 }, cssClass: 'colSeperator' }
      ]
    }
  ];



  const summaryItems = [
    <TotalItem
      key={0}
      column={keyField}
      summaryType="count"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Count: ", "")}`}
    />,
    <TotalItem
      key={1}
      column="adet"
      summaryType="sum"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <TotalItem
      key={2}
      column="maliyet"
      summaryType="sum"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <TotalItem
      key={3}
      column="deger"
      summaryType="sum"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <TotalItem
      key={4}
      name="kar"
      summaryType="custom"
      showInColumn="kar"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "") + ' *'} `}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />,
    <TotalItem
      key={5}
      name="usdAlinsaydiKar"
      summaryType="custom"
      showInColumn="usdAlinsaydiKar"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "") + ' *'}`}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />,
    <TotalItem
      key={6}
      name="tuikTufe"
      summaryType="custom"
      showInColumn="tuikTufe"
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "") + ' *'}`}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />,
    <GroupItem
      key={7}
      name="adet"
      column="adet"
      summaryType="count"
      showInColumn="adet"
    />,
    <GroupItem
      key={8}
      name="birimMaliyet"
      summaryType="custom"
      showInColumn="birimMaliyet"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 6
      }}
    />,
    <GroupItem
      key={9}
      column="adet"
      summaryType="sum"
      showInColumn="adet"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
    />,
    <GroupItem
      key={10}
      column="maliyet"
      summaryType="sum"
      showInColumn="maliyet"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <GroupItem
      key={11}
      column="deger"
      summaryType="sum"
      showInColumn="deger"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Top: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <GroupItem
      key={12}
      name="kar"
      summaryType="custom"
      showInColumn="kar"
      alignByColumn={true}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />,
    <GroupItem
      key={13}
      name="gun"
      column="gun"
      summaryType="avg"
      showInColumn="gun"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "")}`}
      valueFormat={{
        precision: 2
      }}
    />,
    <GroupItem
      key={14}
      name="gunlukOrtalamaKar"
      column="gunlukOrtalamaKar"
      summaryType="avg"
      showInColumn="gunlukOrtalamaKar"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "")}`}
      valueFormat={{
        type: "fixedPoint",
        precision: 2
      }}
    />,
    <GroupItem
      key={15}
      name="usdAlinsaydiKar"
      summaryType="custom"
      showInColumn="usdAlinsaydiKar"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "")}`}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />,
    <GroupItem
      key={16}
      name="tuikTufe"
      summaryType="custom"
      showInColumn="tuikTufe"
      alignByColumn={true}
      customizeText={(itemInfo) => `${itemInfo.valueText.replace("Ort: ", "")}`}
      valueFormat={{
        type: "percent",
        precision: 2
      }}
    />
  ];



  return (

    <div>

      <div className={'content-block'}><h2>{title} Portföyüm</h2>
        <SelectBox
          key='portfoyId'
          width={'200px'}
          visible={false}
          deferRendering={false}
          dataSource={portfoylerimDataSource}
          valueExpr="portfoyId"
          displayExpr="portfoyAdi"
          ref={sbPortfoylerim}
          value={portfoyId}
          // inputAttr={productLabel}
          onValueChanged={(e) => {
            // console.error(e)
            // if (e !== portfoyId) {
            setPortfoyId(e.value)
            // }
          }}
        ></SelectBox>
      </div>

      <div className={'content-block'}>
        <div className='divToolbar'>
          {keyField !== "varlikId" &&
            <div className='divInfoText'>
              {title} fiyatları güncellenme tarihi: {sonGuncellemeTarihi}
            </div>
          }
          {keyField === "varlikId" &&
            <div className='divInfoText'>
              {title} fiyatları sistem tarafından otomatik güncellenmez, varlıklarınız fiyatlarını kendiniz girebilirsiniz.
            </div>
          }
        </div>
      </div>
      <br></br>

      <div className={'content-block my-scrollable-grid'}>
        <DataGrid
          columns={columns}
          key='portfoyDetayId'
          onEditorPreparing={onEditorPreparing}
          // dataSource={gridDataSource}
          showBorders={true}
          width={"100%"}
          height={603}
          wordWrapEnabled={true}
          allowColumnReordering={false}
          allowColumnResizing={false}
          columnResizingMode={'widget'}
          columnMinWidth={70}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={'dx-portfoy dx-swatch-additional'}
          noDataText="Portföyünüze veri eklemek için + butonuna basınız"
          // onToolbarPreparing={handleToolbarPreparing}
          hoverStateEnabled={true}
          ref={grid}>
          <Paging enabled={false}></Paging>
          <Export enabled={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar='always' mode='standard' />
          <Grouping autoExpandAll={false} />
          <GroupPanel visible={true} emptyPanelText="Gruplamak istediğiniz kolonları buraya sürükleyin" />
          <Editing
            mode="batch"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            confirmDelete={true}
          />
          <SearchPanel visible={true} width={240} placeholder={'Ara...'} />
          <FilterRow visible={true} />

          <Summary calculateCustomSummary={calculateCustomSummary}>
            {summaryItems}
          </Summary>
          <SortByGroupSummaryInfo summaryItem="gunlukOrtalamaKar" sortOrder="desc" />

        </DataGrid>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>* Ağırlıklı ortalama</div>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>** {new Date().toLocaleString("tr-TR", { month: "long" })} ayının enflasyonu henüz açıklanmadığı için dahil değildir</div>
      </div>

    </div>
  )
}