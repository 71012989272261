export const navigation = [
  {
    text: 'Güncel Durum',
    path: '/home',
    icon: 'datapie'
  },
  // {
  //   text: 'Examples',
  //   icon: 'folder',
  //   items: [
  //     {
  //       text: 'Profile',
  //       path: '/profile'
  //     },
  //     {
  //       text: 'Tasks',
  //       path: '/tasks'
  //     }
  //   ]
  // }, 
  {
    text: 'Fon Portföyüm',
    path: '/fon-portfoyum',
    icon: 'folder'
  },
  {
    text: 'Hisse Portföyüm',
    path: '/hisse-portfoyum',
    icon: 'chart'
  },
  {
    text: 'Madenler Portföyüm',
    path: '/kiymetli-madenler-portfoyum',
    icon: 'isblank'
  },
  {
    text: 'Döviz Portföyüm',
    path: '/doviz-portfoyum',
    icon: 'money'
  },
  {
    text: 'Varlık Portföyüm',
    path: '/varlik-portfoyum',
    icon: 'home'
  },
  {
    text: 'Kripto Portföyüm',
    path: '/kripto-portfoyum',
    icon: 'key'
  },
  {
    text: 'Performans Raporu',
    path: '/performans-raporu',
    icon: 'datatrending'
  }
  ];
