import React from 'react';

import 'whatwg-fetch';
import './crypto-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function CryptoPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="kriptoParaId" textField="kriptoParaAdi" codeField="sembol" stringField="KriptoPara" stringFieldPulural="KriptoParalar" title="Kripto" portfoyTipiId={6}  />
    </React.Fragment>
  )
}