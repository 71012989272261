import React, { useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  StringLengthRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { changePassword } from '../../api/auth';

import './ChangePasswordForm.scss';


const notificationText = 'Şifreniz başarıyla değiştirilmiştir, yeni şifreniz ile giriş yapabilirsiniz.';

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: '' });
  const { recoveryCode } = useParams();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current;
    setLoading(true);

    const result = await changePassword(recoveryCode, password);
    setLoading(false);

    if (result.isOk) {
      navigate('/giris-yap');
      notify(notificationText, 'success', 3000);
    } else {
      notify(result.message, 'error', 3000);
    }
  }, [navigate, recoveryCode]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={'change-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Şifre gerekli" />
          <StringLengthRule
            message="Şifre uzunluğu 6 ile 12 karakter arasında olmalıdır"
            max={12} min={6}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Şifre gerekli" />
          <StringLengthRule
            message="Şifre uzunluğu 6 ile 12 karakter arasında olmalıdır"
            max={12} min={6}
          />
          <CustomRule
            message={'Şifreler aynı değil!'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Şifremi Değiştir'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Şifre', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Şifre (tekrar)', mode: 'password' };
