import React from 'react';

import 'whatwg-fetch';
import './shares-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function SharesPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="hisseId" textField="hisseAdi" codeField="hisseKodu" stringField="Hisse" stringFieldPulural="Hisseler" title="Hisse" portfoyTipiId={2}  />
    </React.Fragment>
  )
}