import React from 'react';

import 'whatwg-fetch';
import './performance-report.scss';


import PortfolioGrid from '../../components/performance-grid/PerformanceGrid';


export default function PerformanceReport() {

 


  return (
    <React.Fragment>
      <PortfolioGrid />
    </React.Fragment>
  )
}