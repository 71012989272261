import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import Logo from '../../components/logo/Logo';
import ss from '../landing-page/fgss.png'

import './LandingPage.scss';

export default function LandingPage() {
  const navigate = useNavigate();



  const onLoginClick = useCallback(() => {
    navigate('/giris-yap');
  }, [navigate]);


  const onCreateAccountClick = useCallback(() => {
    navigate('/uye-ol');
  }, [navigate]);


  return (
    <div className={'landing-page'}>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Logo></Logo>
          <h5>
            Farklı platformlardaki Yatırım ve Birikim portföylerinizin durumunu tek bir uygulamadan takip edebilmeniz için geliştirildi. 
            Veri odaklı kararlar verin, finansal büyümenizi görselleştirin ve mali geleceğinizin kontrolünü ele alın.
          </h5>

          <div style={{ marginTop: 10, marginBottom: 40 }}>
            <a href="https://apps.apple.com/tr/app/yatirimi/id6480345591" rel="noreferrer" style={{ marginRight: 40 }} target='_blank'>
              <img src="https://losmedia.com/logoappstore.png" width={120} alt='appstore' />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.yatirimi" rel="noreferrer" target='_blank'>
              <img src="https://losmedia.com/logoplaystore.png" width={120} alt='playstore' />
            </a>
          </div>


          <div>
            <Button
              icon="check"
              width={300}
              text="Hemen ücretsiz üye ol!"
              type="default"
              stylingMode="contained"
              onClick={onCreateAccountClick}
            />
            <Button
              icon="to"
              width={150}
              text="Giriş Yap"
              type="default"
              stylingMode="outlined"
              onClick={onLoginClick}
            />
          </div>
        </div>
      </div>

      <div className='credit'>
        <a href="https://losmedia.com" rel="noreferrer" target='_blank'>
          <img src="https://losmedia.com/wp-content/uploads/2021/12/los3beyazbg2-300x132-2.png" alt="losmedia-logo" width={120} />
        </a>
      </div>
    </div>
  );
}

