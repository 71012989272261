import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './Logo.scss';
import YatirimiLogo from '../../images/yatirimi-logo.png';


export default function Logo() {
  const navigate = useNavigate();

  function onLogoClick() {
    navigate('/');
  }

  return (
    <div id='logo' onClick={onLogoClick} >
      <img src={YatirimiLogo} width={160}></img>
    </div>
  )
}
