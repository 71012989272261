import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, LandingPage, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';
import { UyelikSozlesmesiPage } from './pages';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/default'
        element={
          <LandingPage />
        }
      />
      <Route
        path='/giris-yap'
        element={
          <SingleCard title="">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/uye-ol'
        element={
          <SingleCard title="">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route
        path='/sifremi-unuttum'
        element={
          <SingleCard
            title=""
            description=""
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/sifre-degistir/:recoveryCode'
        element={
          <SingleCard title="">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/uyelik-sozlesmesi'
        element={
          <UyelikSozlesmesiPage />
        }
      />
      <Route path='*' element={<Navigate to={'/default'} />}></Route>
    </Routes>
  );
}
