import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
  StringLengthRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
// import { createAccount } from '../../api/auth';
import { useAuth } from '../../contexts/auth';


import './CreateAccountForm.scss';

export default function CreateAccountForm() {
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ eposta: '', sifre: '' });

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { eposta, sifre } = formData.current;
    setLoading(true);

    const result = await signUp(eposta, sifre);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 3000);
    }


  }, [signUp]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.sifre,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'eposta'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}>
          <RequiredRule message="E-posta gerekli" />
          <EmailRule message="E-posta hatalı" />
          <StringLengthRule
            message="E-posta uzunluğu 3 ile 320 karakter arasında olmalıdır"
            max={320} min={3}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'sifre'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}>
          <RequiredRule message="Şifre gerekli" />
          <StringLengthRule
            message="Şifre uzunluğu 6 ile 12 karakter arasında olmalıdır"
            max={12} min={6}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'sifreTeyit'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}>
          <RequiredRule message="Şifre gerekli" />
          <CustomRule
            message={'Şifreler aynı değil!'}
            validationCallback={confirmPassword}
          />
          <StringLengthRule
            message="Şifre uzunluğu 6 ile 12 karakter arasında olmalıdır"
            max={12} min={6}
          />
          <Label visible={false} />
        </Item>
        <Item>
          <div className='policy-info'>
            Üyeliğinizi oluşturduğunuzda <Link to={"/uyelik-sozlesmesi"}>Üyelik Sözleşmesini</Link> kabul etmiş olursunuz.
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}>
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Üye Ol'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Zaten bir hesabınız var mı? <Link to={'/giris-yap'}>Giriş Yapın</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'E-posta', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Şifre', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Şifre (tekrar)', mode: 'password' };
