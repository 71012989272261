import React, { useState, useRef } from 'react';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import DataGrid, {
  Paging,
  FilterRow,
  Scrolling,
  Export,
  SearchPanel,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';
import { useAuth, serviceUrl } from '../../contexts/auth';
import { SelectBox } from 'devextreme-react';




export default function PerformanceGrid() {
  const { user } = useAuth();
  let grid = useRef(null); // Create a ref

  const [usdAlinsaydiKar, setUsdAlinsaydiKar] = useState(0);
  const [tuikTufe, setTuikTufe] = useState(0);
  const [raporTuru, setRaporTuru] = useState(3);
  const [raporTipi, setRaporTipi] = useState(11);

  const [keyField, setKeyField] = useState("fonId");
  const [textField, setTextField] = useState("fonAdi");
  const [codeField, setCodeField] = useState("fonKodu");
  const [stringField, setStringField] = useState("Fon");
  const [stringFieldPulural, setStringFieldPulural] = useState("Fonlar");



  const fetchData = async (endpoint, method, payload = undefined) => {
    try {


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + user.token);

      var proxyOptions = {
        method: method,
        headers: myHeaders,
      };

      if (payload !== undefined) {
        proxyOptions.body = JSON.stringify(payload)
      }


      const response = await fetch(`${serviceUrl}` + endpoint, proxyOptions);
      // debugger;
      if (response.ok && response.status === 200) {
        const data = await response.json();
        return data;
      } else {

        const text = await response.text();
        return text;
      }
    } catch (error) {
      console.log(error.message);
    }
  };



  const gridDataSource = new CustomStore({
    key: 'id',
    async load() {
      const res = await fetchData("/PortfoyDetay/GetPerformansRaporu/" + raporTuru.toString() + "/" + raporTipi.toString(), "GET");
      setTuikTufe(res.tuikTufe);
      setUsdAlinsaydiKar(res.usdAlinsaydiKar);
      return res.performanslar;
    },
  });



  const entityDataSource = new CustomStore({
    key: { keyField },
    loadMode: 'raw',
    load: () => {
      return fetchData("/" + stringField + "/Get" + stringFieldPulural, "GET")
    }
  });




  const onExporting = function (e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        options.excelCell.font = { name: 'Arial', size: 12 };
        options.excelCell.alignment = { horizontal: 'left' };
      }
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Belge-' + new Date().toLocaleDateString("tr-TR") + '-' + new Date().toLocaleTimeString("tr-TR") + '.xlsx');
        });
    });
  }


  const columns = [
    {
      dataField: "id",
      caption: stringField,
      visible: true,
      width: '100%',
      minWidth: 300,
      dataType: 'number',
      allowHeaderFiltering: false,
      encodeHtml: false,
      lookup: {
        keyField: keyField,
        dataSource: entityDataSource,
        valueExpr: keyField,
        displayExpr: item => `${item[codeField]} - ${item[textField]} ${raporTuru !== 1 ? "<a style='text-decoration:none' href='https://www.tefas.gov.tr/FonAnaliz.aspx?FonKod=" + item[codeField] + "' target='_blank'>🔗</a>" : ""}`,
      }
    },
    { dataField: "fiyat", allowHeaderFiltering: false, dataType: "number", caption: "İlk Fiyat", width: 120, allowEditing: false, format: { type: "fixedPoint", precision: 6 } },
    { dataField: "sonFiyat", allowHeaderFiltering: false, dataType: "number", caption: "Güncel Fiyat", width: 120, allowEditing: false, format: { type: "fixedPoint", precision: 6 } },
    { dataField: "artis", allowHeaderFiltering: false, sortOrder: 'desc', dataType: "number", caption: "Getiri %", width: 120, allowEditing: false, format: { type: "percent", precision: 2 } },
    // { dataField: "gunlukOrtalamaKar", caption: "Günlük Kâr", width: 100, allowEditing: false, format: { type: "fixedPoint", precision: 2 }, sortOrder: "desc" },
  ];


  const items = [
    { id: 1, text: 'Son 1 hafta' },
    { id: 2, text: 'Son 2 hafta' },
    { id: 3, text: 'Son 1 ay' },
    { id: 4, text: 'Son 3 ay' },
    { id: 5, text: 'Son 6 ay' },
    { id: 6, text: 'Son 9 ay' },
    { id: 7, text: 'Son 1 yıl' },
    { id: 8, text: 'Son 2 yıl' },
    { id: 9, text: 'Son 3 yıl' },
    { id: 10, text: 'Son 4 yıl' },
    { id: 11, text: '2024 yılbaşından bugüne' },
    { id: 12, text: '2023 yılbaşından bugüne' },
    { id: 13, text: '2022 yılbaşından bugüne' },
    { id: 14, text: '2021 yılbaşından bugüne' },
    { id: 15, text: '2020 yılbaşından bugüne' },
    { id: 16, text: '2023 yılı' },
    { id: 17, text: '2022 yılı' },
    { id: 18, text: '2021 yılı' },
    { id: 19, text: '2020 yılı' }
  ];


  const raporTurleri = [
    { id: 1, text: 'Hisseler' },
    { id: 2, text: 'Fonlar - Tümü' },
    { id: 3, text: 'Fonlar - Serbest fonlar hariç' },
  ]

  return (

    <div>

      <div className={'content-block'}>
        <div className='divToolbar'>
          <div className='divInfoText' style={{ flexDirection: window.innerWidth < 1200 ? 'column' : 'row', display: 'flex', gap: 10 }}>
            <SelectBox
              label='Rapor Türü'
              items={raporTurleri}
              displayExpr={item => item.text}
              valueExpr={item => item.id}
              value={raporTuru}
              showClearButton={false}
              placeholder="Seçiniz"
              width={300}
              onValueChanged={(e) => {
                setKeyField(e.value === 1 ? 'hisseId' : 'fonId')
                setCodeField(e.value === 1 ? 'hisseKodu' : 'fonKodu')
                setTextField(e.value === 1 ? 'hisseAdi' : 'fonAdi')
                setStringField(e.value === 1 ? 'Hisse' : 'Fon')
                setStringFieldPulural(e.value === 1 ? 'Hisseler' : 'Fonlar')

                setRaporTuru(e.value)
              }} />

            <SelectBox
              label='Rapor Tarihi'
              items={items}
              displayExpr={item => item.text}
              valueExpr={item => item.id}
              value={raporTipi}
              showClearButton={false}
              placeholder="Seçiniz"
              width={300}
              onValueChanged={(e) => { setRaporTipi(e.value) }} />

            {usdAlinsaydiKar !== undefined && usdAlinsaydiKar > 0 &&
              <div style={{ marginTop: 4 }}>
                <b> Dolar Artışı:</b> %{usdAlinsaydiKar.toFixed(2)}
                <br></br>
                <b> Tüik Tüfe:</b> %{tuikTufe.toFixed(2)}  &nbsp;&nbsp;  {new Date().toLocaleString("tr-TR", { month: "long" })} ayının enflasyonu henüz açıklanmadığı için dahil değildir
              </div>
            }


          </div>
        </div>
      </div>

      <br>
      </br>

      <div className={'content-block'}>
        <DataGrid
          columns={columns}
          key='id'
          dataSource={gridDataSource}
          showBorders={true}
          width={"100%"}
          height={603}
          wordWrapEnabled={true}
          allowColumnReordering={false}
          allowColumnResizing={false}
          columnResizingMode={'widget'}
          columnMinWidth={70}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={'dx-portfoy dx-swatch-additional'}
          noDataText=""
          hoverStateEnabled={true}
          ref={grid}>
          <Paging enabled={false}></Paging>
          <Export enabled={true} />
          <HeaderFilter visible={true} />
          <Scrolling showScrollbar='always' mode='virtual' useNative={true} />
          <SearchPanel visible={true} width={240} placeholder={'Ara...'} />
          <FilterRow visible={true} />
        </DataGrid>
      </div>

    </div >
  )
}