import React from 'react';


export default function CerezPolitikasi() {

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Çerez Politikası</h4>
          <p>

            <p>Gizliliğiniz bizim için önemlidir. Gizlilik ve güvenlik haklarınız temel prensibimizdir. Kullanıcılarımızın kişisel bilgilerinin gizliliğini korumak amacıyla sistem ve internet altyapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır.</p>

            <p>Web sitemizi herhangi bir kişisel bilgi vermeden ziyaret edebilirsiniz. Ürünlerimiz ve servislerimiz hakkında bilgi alarak ihtiyaçlarınızı karşılayabilirsiniz. Ziyaretlerde site kullanımına ilişkin bilgi toplamak için çerez kullanılmaktadır. Buradaki amacımız; web sitemizi ziyaret eden kullanıcıya kolaylık sağlamak ve işleyişi daha iyi nasıl geliştirebileceğimizi çalışabilmektir.</p>

            <b>Çerez Nedir?</b>

            <p>Çerez, bir web sitesini ziyaret ettiğinizde bilgisayarınıza gönderilen ve bir dizi karakter içeren küçük bir dosyadır. Çerezler bir web sitesinin daha kullanıcı dostu deneyim sunmasını amaçlar. Kişisel ihtiyaçlarınıza daha uygun ve hızlı bir ziyaret deneyimi yaşatmak için kişiselleştirilmiş web sayfaları sunulabilmesine olanak vermektedir. Çerezler bilgisayarınızda veya dosyalarınızda depolanan kişisel verileriniz de dahil herhangi bir bilgi toplayamamaktadır.</p>

            <b>Çerez Türleri</b>

            <p>Geçerlilik sürelerine göre Kalıcı Çerez ve Geçici Çerez olarak iki çerez tipi bulunmaktadır:</p>

            <ul>
              <li>Geçici Çerezler: Internet sitesini ziyaret ettiğiniz esnada oluşturulur ve sadece tarayıcınızı kapatıncaya kadar geçerlidirler.</li>
              <li>Kalıcı Çerezler: Internet sitesini ziyaret ettiğinizde oluşturulur ve siz silinceye veya süreleri doluncaya kadar kalırlar. Ayarlarınız ile uyumlu kişiselleştirilmiş bir deneyim sunma gibi işlemler yapmak için kalıcı çerezler kullanılırlar.</li>
            </ul>

            <b>Çerezlerin Kullanım Amacı</b>

            <p>Web sitemiz geçici ve kalıcı tip çerezleri, sizi web sitemizin diğer kullanıcılarından ayırt etmek, ilgi alanlarınıza ve tercihlerinize göre düzenleme yapmak için kullanılmaktadır. Tarafından tasarlanmış çerezlerin yanı sıra üçüncü taraflardan alınan uygulama ve hizmetler kapsamında da çerez kullanılabilmektedir.</p>

            <b>Çerezleri Yönetme</b>

            <p>Hemen hemen tüm internet tarayıcıları, çerezleri otomatik olarak kabul edecek şekilde tanımlıdır. Ancak çerezleri dilediğiniz gibi kontrol edebilir veya silebilirsiniz. Çerezleri yönetmek tarayıcıdan tarayıcıya farklılık gösterdiğinden ayrıntılı bilgi almak için:</p>

            <ul>
              <li>Tarayıcınızın ayarlarından değiştirebilir veya</li>
              <li>Tarayıcınızın yardım menüsünden kontrol edebilirsiniz.</li>
            </ul>

            <b>Çerezleri Silme</b>

            <p>Çerezleri silmeyi tercih ederseniz ilgili web sitesindeki tercihleriniz silinecektir. Ayrıca, çerezleri tamamen engellemeyi tercih ederseniz birçok web sitesi düzgün çalışmayabilir.</p>

            <b>Daha Fazla Bilgi</b>

            <p>Çerezler hakkında daha fazla bilgi için aşağıdaki kaynaklara göz atabilirsiniz:</p>

            <ul>
              <li><a href="https://www.kvkk.gov.tr/">Kişisel Verileri Koruma Kurumu</a></li>
              <li><a href="https://www.aboutcookies.org/">Çerez Rehberi</a></li>
            </ul>

            <b>İletişim Bilgileri</b>

            <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

            <ul>
              <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
            </ul>

            
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}