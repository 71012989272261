import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { Link } from 'react-router-dom';


export default function Content() {

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <Footer>
        <div className='footerWrapper'>
          <div>
            © {new Date().getFullYear()} {appInfo.title} by <a style={{ textDecoration: 'none' }} href="https://losmedia.com" target='_blank'>Losmedia</a>
          </div>
          <div>
            <Link style={{ textDecoration: 'none' }} to={'/risk-uyarisi'}>Risk Uyarısı </Link>
            • <Link style={{ textDecoration: 'none' }} to={'/gizlilik-politikasi'}>Gizlilik Politikası </Link>
            • <Link style={{ textDecoration: 'none' }} to={'/feragat-bildirisi'}>Feragat Bildirisi </Link>
            • <Link style={{ textDecoration: 'none' }} to={'/cerez-politikasi'}>Çerez Politikası </Link>
            • <Link style={{ textDecoration: 'none' }} to={'/kullanim-kosullari'}>Kullanım Koşulları </Link>
            • <Link style={{ textDecoration: 'none' }} to={'/kisisel-verilerin-korunmasi-ve-islenmesi'}>Kişisel Verilerin Korunması ve İşlenmesi</Link>
          </div>
        </div>
        <br></br>
        <p style={{ fontSize: '11px' }}>Burada yer alan yatırım bilgi, yorum ve tavsiyeleri yatırım danışmanlığı kapsamında değildir. Yatırım danışmanlığı hizmeti, aracı kurumlar, portföy yönetim şirketleri, mevduat kabul etmeyen bankalar ile müşteri arasında imzalanacak yatırım danışmanlığı sözleşmesi çerçevesinde sunulmaktadır. Burada yer alan yorum ve tavsiyeler, yorum ve tavsiyede bulunanların kişisel görüşlerine dayanmaktadır. Bu görüşler, mali durumunuz ile risk ve getiri tercihlerinize uygun olmayabilir. Bu nedenle, sadece burada yer alan bilgilere dayanılarak yatırım kararı verilmesi, beklentilerinize uygun sonuçlar doğurmayabilir.
          {/* BİST isim ve logosu 'Koruma Marka Belgesi' altında korunmakta olup izinsiz kullanılamaz, iktibas edilemez, değiştirilemez. BİST ismi altında açıklanan tüm bilgilerin telif hakları tamamen BİST'a ait olup, tekrar yayımlanamaz. BİST piyasalarında oluşan tüm verilere ait telif hakları tamamen BİST’e ait olup, bu veriler tekrar yayınlanamaz. Analizlerde, piyasa kapanış verileri kullanılmaktadır. */}
        </p>
      </Footer>


    </SideNavBarLayout>
  );
}

