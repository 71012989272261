import React from 'react';
import Logo from '../../components/logo/Logo'


export default function UyelikSozlesmesi() {

  return (
    <React.Fragment>
      <div className={'content-block'} style={{ width: '100%' }} >
        <div className={'dx-card responsive-paddings'}>

          <Logo />


          <h4 style={{ fontWeight: 'bold' }}>Üyelik Sözleşmesi</h4>


          <b>1. Giriş</b>

          <p>Yatirimi.com, kullanıcılarına ("Kullanıcı" veya "Siz") finansal piyasalar ve yatırımlar hakkında bilgi ve araçlar sunan bir web sitesi ve mobil uygulamadır. Bu Üyelik Sözleşmesi ("Sözleşme"), Kullanıcılar ile Yatirimi.com arasında Yatirimi.com'un ve yatirimi mobil uygulamasının kullanımına ilişkin şartları ve koşulları düzenler.</p>

          <b>2. Kabul</b>

          <p>Yatirimi.com'u veya mobil uygulamayı kullanmadan önce bu Sözleşmeyi dikkatlice okumanız ve kabul etmeniz önemlidir. Bu Sözleşmeyi kabul ederek, tüm şartlarına ve koşullarına bağlı kalmayı kabul edersiniz.</p>

          <b>3. Üyelik</b>

          <p>Yatirimi.com'u veya mobil uygulamayı kullanmak için bir üyelik hesabı oluşturmanız gerekir. Üyelik hesabı oluştururken, e-posta adresiniz ve şifreniz gibi belirli bilgileri sağlamanız istenecektir. Hesabınız için doğru ve güncel bilgiler sağlamanız ve bu bilgileri güncel tutmanız önemlidir.</p>

          <b>4. Hizmetler</b>

          <p>Yatirimi.com, Kullanıcılara aşağıdakiler de dahil olmak üzere çeşitli hizmetler sunar:</p>

          <ul>
            <li><b>Finansal Piyasa Bilgileri:</b> Hisse senedi fiyatları, döviz kurları, faiz oranları ve diğer finansal piyasa verileri.</li>
            <li><b>Yatırım Takibi Araçları:</b> Yatırım portföylerinizin sanal kopya kayıtlarını oluşturma ve yönetme araçları, hisse senedi tarama araçları ve diğer yatırım araçları.</li>
            <li><b>Eğitim Materyalleri:</b> Finansal piyasalar ve yatırımlar hakkında makaleler, videolar ve diğer eğitim materyalleri.</li>
          </ul>

          <b>5. Ücretler</b>

          <p>Yatirimi.com'un temel hizmetleri ücretsizdir. Yatirimi.com, bazı ek hizmetler için ücret talep edebilir. Ücretli hizmetlere abone olmadan önce, ilgili ücretleri ve ödeme koşullarını dikkatlice incelemeniz önemlidir.</p>

          <b>6. Kullanıcı Sorumlulukları</b>

          <p>Yatirimi.com'u veya mobil uygulamayı kullanırken aşağıdakileri yapmamayı kabul edersiniz:</p>

          <ul>
            <li>Yanlış veya yanıltıcı bilgi sağlamak.</li>
            <li>Yasa dışı veya yasa dışı faaliyetlerle ilgili içerik yayınlamak.</li>
            <li>Spam veya istenmeyen e-posta göndermek.</li>
            <li>Diğer Kullanıcıların hesaplarını hacklemek veya ele geçirmek.</li>
            <li>Yatirimi.com'un altyapısına zarar vermek veya bozmak.</li>
          </ul>

          <b>7. Fikri Mülkiyet</b>

          <p>Yatirimi.com'daki ve mobil uygulamadaki tüm içerik, Yatirimi.com'e aittir ve telif hakkı, ticari marka ve diğer fikri mülkiyet yasalarıyla korunmaktadır. Kullanıcılar, Yatirimi.com'daki veya mobil uygulamadaki içeriği Yatirimi.com'in önceden yazılı izni olmadan kopyalayamaz, dağıtamaz veya değiştiremez.</p>

          <b>8. Sorumluluk Reddi</b>

          <p>Yatirimi.com'da ve mobil uygulamada sunulan bilgiler yalnızca bilgi amaçlıdır ve yatırım tavsiyesi olarak yorumlanmamalıdır. Yatırım kararları vermeden önce kendi araştırmanızı yapmanız ve bir finansal danışmana danışmanız önemlidir.</p>

          <b>9. Fesih</b>

          <p>Yatirimi.com, herhangi bir zamanda ve herhangi bir nedenle, Yatirimi.com'a veya mobil uygulamaya erişiminizi sonlandırabilir veya hesabınızı feshedebilir. Yatirimi.com, Sözleşmeyi ihlal etmeniz durumunda da hesabınızı feshedebilir.</p>

          <b>10. Uyuşmazlık Çözümü</b>

          <p>Bu Sözleşmeden kaynaklanan veya bu Sözleşmeyle ilgili herhangi bir uyuşmazlık, Türkiye Cumhuriyeti yasalarına göre çözümlenecektir.</p>

          <b>11. İletişim Bilgileri</b>

          <p>Yatirimi.com ile ilgili herhangi bir sorunuz veya yorumunuz varsa, aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <p><b>Yatirimi.com'u veya mobil uygulamayı kullanarak bu Sözleşmeyi kabul ettiğinizi teyit edersiniz.</b></p>


          <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <ul>
            <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
          </ul>
        </div>
      </div>



    </React.Fragment>
  )
}