import React, { useRef } from 'react';
import { useAuth, serviceUrl } from '../../contexts/auth';

import './home.scss';
// import { useNavigate } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';

import fetchData from '../../utils/data-fetcher';

import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';


import PieChart, {
  Series,
  Label,
  Font,
  Size,
} from 'devextreme-react/pie-chart';


export default function Home() {
  // const navigate = useNavigate();

  // const onClick = useCallback(() => {
  //   navigate('/fon-portfoyum');
  // }, [navigate]);



  const { user } = useAuth();
  const pivotGridRef = useRef(null);
  const chartRef = useRef(null);



  const PortfoyTipiEnum = {
    1: "Fonlar",
    2: "Hisseler",
    3: "Madenler",
    4: "Dövizler",
    5: "Varlıklar",
    6: "KriptoParalar",
  };

  const store = new CustomStore({
    key: 'portfoyTipiId',
    async load() {
      let response = await fetchData("/PortfoyDetay/GetPortfoylerToplam", 'GET', user);

      let usdtFiyati = await fetchData("/KriptoPara/GetKriptoParaFiyat", 'POST', user, { sembol: "USDTTRY", tarih: new Date() });

      console.log("usdtFiyati", usdtFiyati);



      const sumByPortfoyTipiId = [];

      response.forEach(item => {
        let adi = PortfoyTipiEnum[item.portfoyTipiId];
        let toplam = item.adet * item.guncelFiyat;

        // if (item.portfoyTipiId === 6) {
        //   console.log("adet", item.adet * item.guncelFiyat);
        // }

        if (item.sembol !== null && item.sembol !== "" && item.sembol !== undefined) {
          if (item.sembol.slice(-4) === "USDT") {
            toplam = toplam * usdtFiyati;
            // console.log("toplam", toplam);
            // console.log("adet", item.adet);
          }
        }




        if (sumByPortfoyTipiId.filter(s => s.adi === adi).length > 0) {
          sumByPortfoyTipiId.filter(s => s.adi === adi)[0].toplam += toplam;
        } else {
          sumByPortfoyTipiId.push({ 'adi': adi, 'toplam': toplam, 'portfoyTipiId': item.portfoyTipiId  });
        }



      });


      return sumByPortfoyTipiId;
    },
  });

  const dataSource = {
    store: store,
    fields: [
      {
        caption: 'Adı',
        dataField: 'adi',
        area: 'row',
        width: 50,
        sortingMethod: function (a, b) {
          // let x = a.value;
          // let y = b.value;
          let value1 = 0;
          let value2 = 0;

          value1 = a.index;
          value2 = b.index;

          if (value1 > value2)
            return 1;
          else if (value1 < value2)
            return -1;
          else if (value1 === value2)
            return 0;

          return 0;
        }
      }, {
        caption: 'Toplam',
        dataField: 'toplam',
        summaryType: 'sum',
        format: { type: "fixedPoint", precision: 2 },
        area: 'data',
        width: 100,
      }],
  };

  




  function customizeLabel(label) {
    return `${label.argumentText}: ${label.percentText}`;
  }


  return (
    <React.Fragment>
      <div className={'home content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Hoş Geldiniz</h4>
          <h5 style={{ fontWeight: 'normal' }}>Yatırım ve birikim portföylerinizin kontrolünü sağlamak için başvurmanız gereken araçtır.
            Veri odaklı kararlar alarak finansal büyümenizi görselleştirin ve mali geleceğinizin kontrolünü ele alın.
          </h5>
          <h5 style={{ marginTop: 10, marginBottom: 10, fontWeight: 'bold' }}>Güncel Durumunuz</h5>

          <div className='divDurum' >
            <div className='divDurumChart'>
              <PieChart
                legend={{
                  visible: false,
                }}
                palette={'Material'}
                dataSource={dataSource}
                ref={chartRef}
                type="pie">
                <Size height={300} width={300} />
                <Series
                  argumentField="adi"
                  valueField="toplam">
                  <Label visible={true}
                    position='inside' backgroundColor='white'
                    customizeText={customizeLabel}>
                    <Font color='black' />
                  </Label>
                </Series>
              </PieChart>
            </div>
            <div className='divDurumPivot'>
              <PivotGrid
                // onContentReady={onContentReady}
                texts={{ grandTotal: "Toplam" }}
                width={'300'}
                id="pivotgrid"
                allowSorting={true}
                allowSortingBySummary={true}
                allowFiltering={true}
                showBorders={false}
                rowHeaderLayout="standard"
                ref={pivotGridRef}
                dataSource={dataSource}>
                <FieldChooser enabled={false}></FieldChooser>
              </PivotGrid>
            </div>
          </div>


        </div>
      </div>
    </React.Fragment >
  )
}