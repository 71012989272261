import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import Logo from '../../components/logo/Logo'


export default function SingleCard({ title, description, children }) {
  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
      <div className={'dx-card content'}>
        <Logo />
        <br />
        <br />
        {/* <div className={'title'}>{title}</div>
        <div className={'description'}>{description}</div> */}
        {children}
      </div>
    </ScrollView>
  )
}
