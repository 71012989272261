import React from 'react';


export default function GizlilikPolitikasi() {

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Gizlilik Politikası</h4>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, kullanıcıların Yatirimi.com sitesi ve yatirimi mobil uygulaması üzerinden ilettikleri kişisel bilgilerini, Gizlilik Politikası ile belirlenen amaçlar ve kapsam dışında, üçüncü kişilere açıklamayacaktır.</p>
          <b>Gizli Bilgilerin Kullanımı</b>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, işbu Gizlilik Politikası ve Kullanıcı Sözleşmesinde tanımlı olan haller haricinde kişisel bilgileri herhangi bir şirket veya üçüncü kişilere açıklamayacaktır.</p>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, kişisel bilgileri kendi bünyesinde müşteri profili belirlemek ve istatistiksel çalışmalar yapmak amacıyla kullanabilecek, Gizlilik Politikası ile belirlenen amaçlar ve kapsam dışında, üçüncü kişilere açıklamayacaktır.</p>
          <b>Gizlilik ve Güvenlik</b>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, kişisel bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümlülüğü olarak addetmeyi, gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.</p>
          <p>Yatirimi.com'un ve yatirimi mobil uygulamasının gerekli bilgi güvenliği önlemlerini almasına karşın, Yatirimi.com sitesine ve yatirimi mobil uygulamasına ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, Yatirimi.com'un veya yatirimi mobil uygulamasının herhangi bir sorumluluğu olmayacaktır.</p>
          <b>Çerezler</b>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, kullanıcılara ve kullanıcıların Yatirimi.com sitesinin ve yatirimi mobil uygulamasının kullanımına dair bilgileri, teknik bir iletişim dosyasını (Kurabiye ya da Cookie olarak anılır) kullanarak elde edebilir. Ancak, kullanıcılar dilerlerse teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyası gönderildiğinde ikaz verilmesini sağlayacak biçimde tarayıcı ayarlarını değiştirebilirler.</p>
          <b>Değişiklikler</b>
          <p>Yatirimi.com ve yatirimi mobil uygulaması, işbu Gizlilik Politikası hükümlerini, dilediği zaman Yatirimi.com sitesinde ve yatirimi mobil uygulamasında yayınlamak suretiyle tek taraflı olarak değiştirebilir. Yatirimi.com'un veya yatirimi mobil uygulamasının değişiklik yaptığı Gizlilik Politikası hükümleri, Yatirimi.com sitesinde ve yatirimi mobil uygulamasında yayınlandığı tarihte yürürlüğe girer.</p>
          <b>İletişim Bilgileri</b>

          <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <ul>
            <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}