import React from 'react';

import 'whatwg-fetch';
import './fund-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function FundPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="fonId" textField="fonAdi" codeField="fonKodu" stringField="Fon" stringFieldPulural="Fonlar" title="Fon" portfoyTipiId={1}  />
    </React.Fragment>
  )
}