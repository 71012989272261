import React from 'react';

import 'whatwg-fetch';
import './metals-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function MetalsPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="kiymetliMadenId" textField="kiymetliMadenAdi" codeField="kiymetliMadenKodu" stringField="KiymetliMaden" stringFieldPulural="KiymetliMadenler" title="Kıymetli Madenler" portfoyTipiId={3}  />
    </React.Fragment>
  )
}