import React from 'react';

import 'whatwg-fetch';
import './assets-portfolio.scss';


import PortfolioGrid from '../../components/portfolio-grid/PortfolioGrid';


export default function AssetsPortfolio() {

 


  return (
    <React.Fragment>
      <PortfolioGrid keyField="varlikId" textField="varlikAdi" codeField="varlikKodu" stringField="Varlik" stringFieldPulural="Varliklar" title="Varlık" portfoyTipiId={5}  />
    </React.Fragment>
  )
}