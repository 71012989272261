import React from 'react';


export default function KisiselVerilerinKorunmasiVeIslenmesi() {

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Kişisel Verilerin Korunması ve İşlenmesi Politikası</h4>

          <p>Yatirimi.com, kullanıcılarına ("Kullanıcı" veya "Siz") finansal piyasalar ve yatırımlar hakkında bilgi ve araçlar sunan bir web sitesi ve mobil uygulamadır. Yatirimi.com, Kullanıcılarının kişisel verilerinin güvenliğini ve gizliliğini korumaya önem verir. Bu Kişisel Verilerin Korunması ve İşlenmesi Politikası ("Politika"), Yatirimi.com'in Kullanıcılardan topladığı veya Kullanıcılar tarafından Yatirimi.com'e sağlanan kişisel verilerin nasıl işlendiğini ve korunduğunu açıklar.</p>

          <b>Toplanan Kişisel Veriler</b>

          <p>Yatirimi.com, Kullanıcılardan aşağıdakiler de dahil olmak üzere çeşitli kişisel veriler toplayabilir:</p>

          <ul>
            {/* <li><b>Kimlik Bilgileri:</b> Adınız, soyadınız, doğum tarihiniz, cinsiyetiniz, T.C. kimlik numaranız, pasaport numaranız</li> */}
            <li><b>İletişim Bilgileri:</b> E-posta adresiniz</li>
            <li><b>Hesap Bilgileri:</b> Kullanıcı adınız, şifreniz</li>
            <li><b>Finansal Bilgiler:</b> Yatırım veya birikim portföyü bilgileriniz</li>
            <li><b>Cihaz Bilgileri:</b> IP adresiniz, işletim sisteminiz, tarayıcı türünüz, cihazınızın benzersiz tanımlayıcısı</li>
            <li><b>Kullanım Bilgileri:</b> Web sitemizi veya uygulamamızı nasıl kullandığınız, hangi sayfaları ziyaret ettiğiniz, hangi araçları kullandığınız</li>
          </ul>

          <b>Kişisel Verilerin İşlenme Amacı</b>

          <p>Yatirimi.com, Kullanıcılardan toplanan kişisel verileri aşağıdaki amaçlar için kullanabilir:</p>

          <ul>
            <li><b>Hesap Oluşturma ve Yönetme:</b> Kullanıcılar için hesap oluşturmak ve yönetmek</li>
            <li><b>Hizmetleri Sunmak:</b> Kullanıcılara web sitesi ve uygulama içeriğine erişim sağlamak</li>
            <li><b>Müşteri Desteği:</b> Kullanıcılara müşteri desteği ve teknik yardım sağlamak</li>
            <li><b>İletişim:</b> Kullanıcılar ile e-posta veya diğer iletişim kanalları aracılığıyla iletişim kurmak</li>
            <li><b>Pazarlama:</b> Kullanıcılara ürünlerimiz ve hizmetlerimiz hakkında bilgi vermek</li>
            <li><b>Araştırma ve Geliştirme:</b> Web sitemizi ve uygulamamızı geliştirmek için araştırma ve geliştirme çalışmaları yürütmek</li>
            <li><b>Yasal Yükümlülüklere Uymak:</b> Yasal ve düzenleyici gerekliliklere uymak</li>
          </ul>

          <b>Kişisel Verilerin İşlenme Şekli</b>

          <p>Yatirimi.com, kişisel verileri otomatik ve otomatik olmayan yöntemlerle işleyebilir. Kişisel verileriniz, Yatirimi.com'in veri merkezlerinde veya Yatirimi.com'in adına çalışan üçüncü tarafların veri merkezlerinde saklanabilir.</p>

          <b>Kişisel Verilerin Paylaşımı</b>

          <p>Yatirimi.com, kişisel verilerinizi aşağıdaki durumlarda üçüncü taraflarla paylaşabilir:</p>

          <ul>
            <li><b>Yasal Yükümlülüklere Uymak:</b> Yasal ve düzenleyici gerekliliklere uymak için yetkili kamu kurumları ile</li>
            <li><b>Hizmet Sağlayıcılar:</b> Yatirimi.com'in adına hizmet sunan üçüncü taraflarla (örneğin, web sitesi barındırma sağlayıcıları, e-posta pazarlama sağlayıcıları)</li>
            <li><b>İş Ortakları:</b> Ortak promosyonlar veya pazarlama faaliyetleri yürütmek için iş ortakları ile</li>
          </ul>

          <b>Kişisel Verilerin Güvenliği</b>

          <p>Yatirimi.com, kişisel verilerinizin güvenliğini korumak için teknik ve idari güvenlik önlemleri alır. Bu önlemler şunları içerir:</p>

          <ul>
            <li>Güvenli veri merkezleri</li>
            <li>Güvenli veri aktarımı</li>
            <li>Erişim kontrolleri</li>
            <li>Veri şifreleme</li>
            <li>Güvenlik duvarları</li>
          </ul>

          <b>Kişisel Verilerinize İlişkin Haklarınız</b>

          <p>Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:</p>

          <ul>
            <li><b>Erişim Hakkı:</b> Kişisel verilerinizin işlenip işlenmediğini öğrenme ve işleniyor ise bunlara erişme hakkınız vardır.</li>
            <li><b>Düzeltme Hakkı:</b> Kişisel verilerinizin eksik veya hatalı olması halinde bunların düzeltilmesini talep etme hakkınız vardır.</li>
            <li><b>Silme Hakkı:</b> Kişisel verilerinizin işlenme amacının ortadan kalkması ve Kişisel Verilerin Korunması Kanunu ("KVKK") kapsamında silinmesi gereken hallerin varlığı halinde silinmesini veya anonim hale getirilmesini talep etme hakkınız vardır.</li>
            {/* <li><b>Veri Transfer Hakkı:</b> Yatirimi.com tarafından sağlanan kişisel verilerinizi başka bir veri sorumlusuna aktarılmasını talep etme hakkınız vardır.</li> */}
            <li><b>İşleme İtiraz Hakkı:</b> Kişisel verilerinizin işlenmesine itiraz etme hakkınız vardır.</li>
          </ul>

          <b>Kişisel Verilerinize İlişkin Taleplerinizi İletme</b>

          <p>Kişisel verilerinize ilişkin yukarıda belirtilen haklarınızı kullanmak için Yatirimi.com'in belirleyeceği yöntemlerle (örneğin, web sitesi üzerinden form doldurma, e-posta gönderme) yazılı olarak başvuruda bulunabilirsiniz.</p>

          <b>Politikanın Değiştirilmesi</b>

          <p>Yatirimi.com, bu Politikayı zaman zaman güncelleyebilir. Güncellemeler, web sitesi veya uygulama üzerinden duyurulacaktır. Politikanın güncel hali web sitesinde veya uygulamada yer alacaktır.</p>

          <b>Uyuşmazlıkların Çözümü</b>

          <p>Bu Politika veya Yatirimi.com'in kişisel verilerinizi işleme şekli ile ilgili herhangi bir uyuşmazlık olması halinde, öncelikle Yatirimi.com ile iletişime geçmeniz önerilir. Yatirimi.com ile çözüme ulaşılamaması halinde KVKK'nın öngördüğü yollara başvurabilirsiniz.</p>

          <b>İletişim Bilgileri</b>

          <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <ul>
            <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
          </ul>

          <p>Bu politika, KVKK hükümleri çerçevesinde hazırlanmış olup, Yatirimi.com'in gizlilik uygulamaları hakkında daha fazla bilgi almak için [Yatirimi.com'un varsa Çerez Politikası sayfasına bağlantı ekleyin] sayfasını inceleyebilirsiniz.</p>


        </div>
      </div>
    </React.Fragment>
  )
}