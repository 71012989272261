import React from 'react';


export default function FeragatBildirisi() {

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <h4 style={{ fontWeight: 'bold' }}>Feragat Bildirisi</h4>
          <p>Tüm veriler ve bilgiler, yalnızca bilgilendirme amaçlı "olduğu gibi" sunulmaktadır. Alım satım, finansal, yatırım, vergi, yasal, muhasebe veya diğer konularda tavsiye niteliğinde değildir. Her türlü alım satım işleminden önce fiyatları doğrulamak için lütfen aracı kurumunuza veya mali temsilcinize danışın.</p>

          <b>Yatirimi.com</b>

          <ul>
            <li>Yatırım danışmanı, mali danışman veya hisse senedi aracısı değildir.</li>
            <li>Sunulan veri ve bilgilerin hiçbiri, bir menkul kıymet veya finansal ürünün satın alınması, satılması veya tutulmasına yönelik bir yatırım tavsiyesi, teklif, öneri veya talep değildir.</li>
            <li>Herhangi bir yatırımın tavsiye edilebilirliği veya uygunluğu konusunda herhangi bir taahhütte bulunmaz.</li>
          </ul>

          <b>Veriler ve Bilgiler</b>

          <ul>
            <li>Genel veya özel yatırım tavsiyesi değildir.</li>
            <li>Yatırım profilinize, hedeflerinize veya beklentilerinize uygun olmayabilir.</li>
            <li>Herhangi bir finansal ürün veya faaliyetin size uygun olup olmadığını değerlendirmek sizin sorumluluğunuzdadır.</li>
            <li>Yatirimi.com, bu ürünlerle ilgili herhangi bir işlemden veya yatırımdan kaynaklanan zararlardan sorumlu değildir.</li>
            <li>Yalnızca sağlanan veri ve bilgilere dayanarak yatırım kararı vermeniz önerilmez.</li>
          </ul>

          <b>Veri Kaynağı</b>

          <ul>
            <li>Finans borsaları ve diğer içerik sağlayıcıları tarafından sağlanır.</li>
            <li>Gecikmeli olabilir.</li>
            <li>Yatirimi.com hiçbir veriyi doğrulamaz ve bunu yapma yükümlülüğünü reddeder.</li>
          </ul>

          <b>Sorumluluk Reddi</b>

          <ul>
            <li>Yatirimi.com, veri veya içerik sağlayıcıları, finansal borsalar ve bağlı kuruluşları:</li>
            <li>Herhangi bir verinin doğru, yeterli veya eksiksiz olduğunu açıkça reddeder.</li>
            <li>Bu tür verilerdeki hatalardan, eksikliklerden, gecikmelerden, kesintilerden veya bunlara dayanarak yapılan işlemlerden sorumlu tutulamaz.</li>
            <li>Yatirimi.com veya veri sağlayıcıları, burada sunulan verileri kullanmanız nedeniyle oluşabilecek herhangi bir zarardan sorumlu değildir.</li>
          </ul>

          <b>Veri Kullanımı</b>

          <ul>
            <li>Burada bulunan hiçbir veri veya bilgiyi, önceden yazılı izin almadan kopyalayamaz, değiştiremez, yeniden biçimlendiremez, indiremez, depolayamaz, yeniden oluşturamaz, yeniden işleyemez, iletemez veya yeniden dağıtamazsınız.</li>
            <li>Bu veri veya bilgileri ticari bir kurumda kullanamazsınız.</li>
          </ul>

          <b>Mülkiyet Hakkı</b>

          <p>Yatirimi.com ve üçüncü taraf veri veya içerik sağlayıcıları, sunulan verilerin ve bilgilerin münhasır mülkiyet haklarına sahiptir.</p>

          <b>Borsa ve Endeksler</b>

          <p>Yatirimi.com'da sunulan tüm borsa ve endekslerin yanı sıra ilgili gecikme sürelerini yukarıdaki tabloda bulabilirsiniz.</p>

          <b>Reklamlar</b>

          <ul>
            <li>Yatirimi.com'da sunulan reklamların sorumluluğu tümüyle reklamın kaynağı olan tarafa aittir.</li>
            <li>Yatirimi.com ve verilerin lisans sahiplerinin hiçbiri, hiçbir reklamı veya reklamlarda teklif edilen ürün veya hizmetleri onaylamaz veya bu konuda sorumluluk üstlenmez.</li>
          </ul>

          <b>Değişiklikler</b>

          <p>Yatirimi.com, bu feragat bildirimini dilediği zaman Yatirimi.com sitesinde ve yatirimi mobil uygulamasında yayınlamak suretiyle tek taraflı olarak değiştirebilir. Değişikliklerin yürürlüğe girme tarihi, Yatirimi.com sitesinde ve yatirimi mobil uygulamasında yayınlandığı tarihtir.</p>

          <b>Uyuşmazlık Çözümü</b>

          <p>Bu feragat bildirimi ve Yatirimi.com'un kullanımı ile ilgili herhangi bir uyuşmazlık, Türkiye Cumhuriyeti yasalarına tabidir.</p>

          <p>Yatirimi.com'u kullanırken lütfen bu feragat bildirimini göz önünde bulundurun.</p>

          <b>İletişim Bilgileri</b>

          <p>Herhangi bir soru veya talebiniz olması durumunda aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>

          <ul>
            <li>E-posta: <a href="mailto:info@yatirimi.com" target="_blank">info@yatirimi.com</a></li>
          </ul>


        </div>
      </div>
    </React.Fragment>
  )
}